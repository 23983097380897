import React from "react";
import FormLogin from "../componets/formLogin"
import "../css/login.css"

 const LoginJk = ()=>{
    return(
      <div className="container-login">
        <FormLogin/>
      </div>
    )
}

export default LoginJk;